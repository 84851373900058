import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import to from 'await-to-js'
import ElementUI from 'element-ui'
// import btn from '@/directives/btn'

import '@/style/index.scss'
import 'element-ui/lib/theme-chalk/index.css'

Vue.config.productionTip = false
Vue.prototype.$to = to
Vue.use(ElementUI)
// Vue.use(btn)

Vue.directive('btn', {
  bind: (el, binding) => {
    el.addEventListener('click', function (evt) {
      el.blur()
    })
  }
})

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
